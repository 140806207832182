import { Link } from 'gatsby';
import * as React from 'react';

import H3Check from '../utils/h3-check';

const StorageSolutions = () => (
  <article className="flex flex-wrap max-w-6xl px-6 py-4 mx-auto mt-20">
    <div className="w-full md:w-1/3">
      <h2 className="text-5xl font-semibold leading-none uppercase heading font-display">
        Frontline <br />
        storage <br />
        solutions
      </h2>
      <div className="hidden md:block">
        <div className="flex flex-col items-center justify-center w-32 h-32 mt-4 text-2xl font-semibold leading-none text-center uppercase rounded-full font-display bg-brand-blue">
          <span className="inline text-brand-orange">Store from </span>
          <span>
            <span className="text-brand-orange">just </span>
            <span className="inline text-white">$2 a day!</span>
          </span>
        </div>
        <div className="flex flex-col items-center justify-center w-32 h-32 mt-4 text-2xl font-semibold leading-none text-center uppercase rounded-full font-display bg-brand-blue">
          <span className="text-brand-orange">No lock in </span>
          <span className="text-white">contract</span>
        </div>
      </div>
    </div>
    <div className="w-full mt-6 md:mt-0 md:w-2/3">
      <div className="rich-text">
        <h2>Shipping containers: our groundbreaking solution</h2>
        <p>
          At{' '}
          <Link to="https://www.frontlineremovals.com.au/">
            Frontline Removals
          </Link>
          , we recognise the need for reliable, secure, and affordable furniture
          storage options. We understand that searching for a storage provider
          can be a bit of a worry. Your belongings are special to you, and we
          get that!
        </p>
        <p>
          That’s why we make it our mission to store your effects in a secure
          area. With high industry standards, professionalism and 24 hour
          surveillance, your belongings are safe with us.
        </p>
        <p>
          Short or long-term, our top-quality, storage containers safeguard your
          belongings from unpredictable weather elements, theft and damage from
          vermin.
        </p>
        <h2>Why store with us?</h2>
        <H3Check title="A safe, monitored option:" />
        <p>
          It is our utmost goal to provide you with peace of mind; knowing that
          your personal effects are in good hands.
        </p>
        <p>
          Guaranteeing you 24 hour video surveillance and round-the-clock
          security patrols, our team keep all storage containers and their
          contents safe.
        </p>
        <H3Check title="A flexible storage solution:" />
        <p>
          Our furniture storage solutions are flexible to your situational
          needs.
        </p>
        <p>
          You might be relocating, but wish to store your items for a little
          while longer, in between homes or offices. Or perhaps you’re heading
          overseas and need to leave some things behind? Whatever it is, we
          cater to a wide variety of storage needs.
        </p>
        <H3Check title="A tailored-to-time option:" />
        <p>
          Our storage container solutions and facilities make for a versatile
          option. Both in terms of contents, but also time.
        </p>
        <p>
          We can secure your belongings for any given length of time — from one
          week to ten years, so be it! Just liaise with us on a pay-per-month
          scheme and we’ll tailor your storage preferences.
        </p>
      </div>
    </div>
  </article>
);

export { StorageSolutions };
