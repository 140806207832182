import { graphql, Link, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { IoIosCall } from 'react-icons/io';

const StorageCTA = () => {
  const {
    site: {
      siteMetadata: { phone, phoneFormatted },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          phone
          phoneFormatted
        }
      }
    }
  `);

  const isBrowser = typeof window !== 'undefined';

  const doFB = () => {
    const eventID = crypto.randomUUID();
    console.log(eventID);
    conversionsAPI(eventID);
    if (isBrowser && window.fbq)
      window.fbq('track', 'Contact', {}, { eventID });
  };

  async function conversionsAPI(eventID) {
    const cookies = document.cookie.split(';');
    let fbp = 'none';
    let fbc = 'none';

    cookies.map((cookie) => {
      if (cookie.includes('_fbp=')) {
        fbp = cookie.slice(cookie.indexOf('_fbp=') + 5);
        console.log(fbp);
      }
    });
    cookies.map((cookie) => {
      if (cookie.includes('_fbc=')) {
        fbc = cookie.slice(cookie.indexOf('_fbc=') + 5);
        console.log(fbc);
      }
    });

    if (fbc === 'none' && window.location.search.includes('fbclid=')) {
      const params = new URL(document.location).searchParams;
      fbc = `fb.1.${Date.now()}.${params.get('fbclid')}`;
    }
    try {
      const res = await fetch('/.netlify/functions/conversions-api', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ eventType: 'Contact', fbp, fbc, eventID }),
      });
      const data = await res.json();
      console.log('Return from netlify functions conversionsAPI =', data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  return (
    <article className="w-full max-w-5xl mx-auto my-auto">
      <div className="flex">
        <div className="p-3 -m-3 overflow-hidden rounded-lg cta">
          <h1 className="text-6xl font-semibold leading-none uppercase font-display text-brand-blue">
            Purpose-build <br />
            storage containers <br />
            for <span className="text-brand-orange">your convenience</span>
          </h1>
          <CTA />
          <div className="flex flex-col mt-4 -mx-2 ">
            <p className="flex-shrink-0 w-full px-2 sm:w-auto">
              <Link
                to="/storage-solutions/#storage-page-bottom-form"
                // @ts-ignore
                offset="12"
                className="flex items-center justify-center w-full mt-4 shadow sm:w-56 hover:shadow-md button"
              >
                Get Quote
              </Link>
            </p>
            <p className="flex-shrink-0 w-full px-2 mb-2 sm:w-auto">
              <a
                onClick={() => doFB()}
                href={`tel:${phone}`}
                className="flex items-center justify-center w-full mt-4 bg-white shadow border-brand-orange text-brand-orange sm:w-56 hover:shadow-md button"
              >
                <IoIosCall />
                <span className="ml-2">Call: {phoneFormatted}</span>
              </a>
            </p>
          </div>

          {/* <div className="w-full max-w-lg mx-0 mt-6 sm:max-w-lg sm:pr-10">
            <div className="overflow-hidden rounded-md">
              <ReviewWidget />
            </div>
          </div> */}
        </div>
      </div>
    </article>
  );
};

function CTA() {
  return (
    <div className="w-full max-w-lg mx-0 mt-12 sm:max-w-lg sm:pr-10">
      <Link
        to="/furniture-removal/"
        className="block p-6 rounded-r-md bg-brand-blue hover:shadow-md"
      >
        <div className="px-3 py-1 leading-none">
          <h2 className="text-4xl font-semibold text-white uppercase font-display">
            Are you thinking about moving,
          </h2>
        </div>
        <div className="px-3 py-1 leading-none rounded bg-brand-orange">
          <h2 className="text-4xl font-semibold text-white uppercase font-display">
            find out about our moving services.
          </h2>
        </div>
      </Link>
    </div>
  );
}

export { StorageCTA };
