import * as React from 'react';

import { Layout, SEO } from '../../components';
import { StorageHero, StorageSolutions } from '../../components/storage';

const StoragePage = () => (
  <Layout formName="storage-page-bottom-form" formTitle="Get in touch with us">
    <SEO
      title="Shipping Containers &amp; Storage Solutions in Port Macquarie"
      description="Get custom storage containers &amp; removal solution in Port Macquarie. Affordable services. Keep your belongings safe and secure. Call us at 1800 549 798."
    />
    <StorageHero />
    <StorageSolutions />
  </Layout>
);

export default StoragePage;
